// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/Inter-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/Inter-Regular.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/Inter-Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/Inter-Medium.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../fonts/Inter-SemiBold.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../fonts/Inter-SemiBold.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../fonts/Inter-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../fonts/Inter-Bold.woff");
var ___CSS_LOADER_URL_IMPORT_8___ = require("../fonts/MaisonNeueExtendedBlack.ttf");
var ___CSS_LOADER_URL_IMPORT_9___ = require("../fonts/Klamp-105-Mono-Bold.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Inter\";font-style:normal;font-weight:400;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\")}@font-face{font-family:\"Inter\";font-style:normal;font-weight:500;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\")}@font-face{font-family:\"Inter\";font-style:normal;font-weight:600;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\")}@font-face{font-family:\"Inter\";font-style:normal;font-weight:700;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff\")}@font-face{font-family:\"Maison Neue Extended Black\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ");font-style:normal;font-weight:700}@font-face{font-family:\"Klamp 105 Mono\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ");font-style:bold;font-weight:700}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
